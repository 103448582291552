
import { LoadingProcessing } from "@/components/templateux/loading/loading-processing";
import { config } from "@/lib/config";
import { getSlugFilter } from "@/lib/utils/getSlugFilter";
import { useSWRWrapper } from "@/lib/utils/hooks/swr-hooks";
import isAdminUser from "@/lib/utils/isAdminUser";
import pageint from "@/lib/utils/pageint";
import { Fragment, useEffect, useState } from "react";

import React from "react";
import { handleReportAdmin } from "@/lib/utils/moderation/handleReportAdmin";
import { blockHandler } from "@/lib/utils/moderation/blockHandler";
import { ignoreHandler } from "@/lib/utils/moderation/ignoreHandler";
import { hasDataArray } from "@/lib/utils/hasDataArrayElement";
import PostCommentSingle from "./post-comment-single";
import { removeHandlerComment, reportHandlerComment } from "./post-handlers";

function PostComments({
     post_id
    ,profiledata
    ,slug
    ,router
    ,cache
    ,set_cache
    ,userdata
    ,openModal
    ,set_comments
}) {


    const [isAdmin,set_isAdmin] = useState(false)
     
    useEffect(() => {


        if (profiledata) {
            
            set_isAdmin(isAdminUser(profiledata?.id            //user_id
                                    ,profiledata?.bol_staff_help    //bol_staff_help
                                    ,profiledata?.staff_json        //staff_json
                                    ,profiledata?.bol_admin_help    //bol_admin_help
                                    ,userdata?.id?.toString()                            //my_id
                                    ,userdata?.bol_admin?.toString()               //global_admin
                                    ,'chat'
                                    ))

        }

      }, [profiledata
        , userdata?.id
        , userdata?.bol_admin
    ]);

    const page = pageint(router.query.page)
  var slugfilter = getSlugFilter(slug)


  const [filter, set_filter] = useState(slugfilter)
  const [filterinput, set_filterinput] = useState(slugfilter)
  const [filteropen, set_filteropen] = useState(false)
  const csort = router.query.sort || config.itemsort
  const [sort, set_sort] = useState(csort)
  const cdir = router.query.dir || config.itemdir
  const [dir, set_dir] = useState(cdir)
  const [sortstr,set_sortstr] = useState(`${config.itemsort} ${config.itemdir}`)



      

  useEffect(() => {
    set_filter(slugfilter)
    set_filterinput(slugfilter)
    if (slugfilter !=='') {
      set_filteropen(true)
    } else {
      set_filteropen(false)
    }
}, [slugfilter])

    useEffect(() => {
      set_sort(csort)
  }, [csort])

  useEffect(() => {
    set_dir(cdir)
  }, [cdir])

  const [commentarraydata_active,set_commentarraydata_active] = useState([]);

  //POST LIKE DATA
  const { 
    data: commentarraydata
  , loading: commentarraydataisLoading
  , error: commentarraydataisError
  //, boundMutate: itemsMutate
                  } = useSWRWrapper(
                    post_id //id
                    ,`/api/private/post_comment/select?id=${post_id}&page=${page}&sort=${sort}&dir=${dir}&cache=${cache}&q=${filter}` //key
                    ,true //retry?
                    ,config.max_retries //max number of retries
                    ,config.retry_interval //retry interval
                    ,0 //refreshinterval (0=none)
                    )

    useEffect(()=>{
        if (!commentarraydataisLoading && !commentarraydataisError) {
            
            let this_ignore_json = userdata?.ignore_json
            if (this_ignore_json?.length > 0) {
              const ignore_filter = JSON.parse(userdata?.ignore_json)
              //run a filter comparison against all messages and stripped out any ignoreed users
              set_commentarraydata_active(commentarraydata?.reverse()?.filter(({ id: id1, target_id: ti1 }) => ignore_filter.some(({ id: id2 }) => (id2 !== id1 && id2 !==ti1  ))));
            } else {
              set_commentarraydata_active(commentarraydata?.reverse());
            }
        }
    },[
         commentarraydata
        ,commentarraydataisLoading
        ,commentarraydataisError
        ,userdata
    ])


    return(<>

                {/* TOP ROW */}
                <div 
                    className="flex w-full flex-col items-center content-center text-left  divide-y divide-gray-700"
                >

                    {(commentarraydataisLoading && commentarraydata_active?.length == 0) && 
                    <div className="p-10">
                         <LoadingProcessing />
                    </div>}

                    {!commentarraydataisLoading && commentarraydata_active?.length == 0 && 
                        <div className="p-10">Nothing here yet!</div>
                        }

                    {(commentarraydata_active?.length > 0)&&
                    <>


                    
                        {commentarraydata_active?.length > 0 &&
                        commentarraydata_active
                        ?.map((e,index) => {
                          
                          if (
                            hasDataArray(userdata?.ignore_json) && JSON.parse(userdata?.ignore_json)?.find((i) => i?.id?.toString() == e?.id?.toString()) //IGNORED USER/
                              ) {
                            return (<Fragment key={index}></Fragment>)
                          }

                          
                            

                            return (<PostCommentSingle
                                      key={index}
                                      itemdata={e}
                                      profiledata={profiledata}
                                      userdata={userdata}
                                      isAdmin={isAdmin}
                                      openModal={openModal}
                                      removeHandler={removeHandlerComment}
                                      blockHandler={blockHandler}
                                      ignoreHandler={ignoreHandler}
                                      handleReportAdmin={handleReportAdmin}
                                      reportHandler={reportHandlerComment}
                                      set_cache={set_cache}
                                      set_comments={set_comments}
                                    />)
                        })}


                    </>}


                
                </div>


    </>)
}

export default PostComments