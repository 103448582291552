import { PlayerContext } from "@/components/player/player-wrapper";
import { countUpBySeconds } from "@/lib/utils/countUp"
import { getTimestamp } from "@/lib/utils/getTimestamp";
import { timerFormat } from "@/lib/utils/timerFormat"
import { useContext, useState } from "react";

export default function PostTranscribe({
    transcribe_json
    ,file
    ,duration
    ,post_id
    ,content_id
    ,content_table
    ,owner_id
    ,owner_name
    ,avatar_url
    ,broadcast_title
    ,start_date
    ,router
}) { 


    
    const {player_state,set_player_state} = useContext(PlayerContext)

    // const [active_sentence,set_active_sentence] = useState({seek:0,index:`0-0`})


    
    const startPlayerTimestamp = async (timestamp) => {

        await set_player_state({});

        let player_object = {...player_state
        ,file:`${file}`
        ,start_seek: getTimestamp(timestamp) !==0 
                    ? timestamp 
                    : 0
        ,seek: getTimestamp(timestamp) !==0 
                ? timestamp 
                : 0
        ,seek_ratio: getTimestamp(timestamp) !==0 
                        ? (timestamp * 100) / duration 
                        : 0
        ,seek_slider: getTimestamp(timestamp) !==0 
                        ? (timestamp * 100) / duration 
                        : 0
        ,post_id: post_id
        ,content_id: content_id
        ,content_table: content_table
        ,user_id: owner_id
        ,user_name: owner_name
        ,player_type: 'file'
        ,title: broadcast_title
        ,duration: duration
        ,stream_start_date: start_date
        ,start_date: start_date
        ,isPlaying:true
        ,isMuted:false
        ,isEnded:false
        ,avatar_url: avatar_url
        ,isHide: "0"
        }

        // console.log("player_object",player_object,display_data)

        await set_player_state(player_object);

    }

    return (<>
    <div className="p-4">
                        {transcribe_json?.map((paragraph, pindex) => {

                        return (<div className="mb-5 text-left font-bold" key={pindex}>Speaker {paragraph.speaker}:

                                    {paragraph.sentences.map((sentences, sindex) => {

                                        // if (active_sentence?.seek < player_state?.seek 
                                        //     && active_sentence?.seek >= Math.round(sentences.start)) {
                                        //     set_active_sentence({seek:player_state?.seek,index:`${pindex}-${sindex}`})
                                        // }

                                        return (<div className="font-normal flex items-center content-center" 
                                        
                                                key={sindex}
                                        >
                                            
                                                    <div className="mr-2 text-blue-400 underline hover:no-underline cursor-pointer"
                                                    
                                                        onClick={()=>{ 
                                                            startPlayerTimestamp(sentences.start)
                                                        }}
                                                    >
                                                        {timerFormat(countUpBySeconds(Math.round(sentences.start)))}
                                                    </div>
                                                    <div 
                                                        className={`flex-1 text-left
                                                            ${player_state?.seek > sentences.start
                                                                ? `text-red-400`
                                                                : ``
                                                            }
                                                        `}
                                                    
                                                    >
                                                        {sentences.text}
                                                    </div>
                                        </div>)
                                    })}
                        </div>)


                        })}
                    </div>
    
    </>)
}