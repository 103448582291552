
import SvgJsx from "@/components/templateux/svg/svg-jsx";
import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";
import PopupMenuOption from "@/components/templateux/popup-menu-option";
import { useRouter } from "next/router";
import { getTimestamp } from "@/lib/utils/getTimestamp";
import { ModalContext } from "@/components/context/modal-wrapper";
import { ifNull } from "@/lib/utils/ifNull";


export default function PostMenuOptions({
   item_id
  ,item_name
  ,shrink=false
  ,userdata
  ,owner_id
  ,timestamp
  ,session
  ,openModal
  ,closeModal
  ,cache
  ,set_cache
  ,permaurl
  ,isAdmin
  ,isContributor
  ,post_date
  ,post_type
  ,deleteHandler
  ,stickyHandler
  ,transcribeHandler
  ,bol_transcribe
  ,bol_replay_host
  ,transcribe_status
  ,bol_sticky
  ,player_state
  ,set_player_state
  ,file
  ,duration
  ,content_id
  ,content_table
  ,owner_name
  ,avatar_url
  ,start_date
  ,stream_status
  ,isOpenEdit
  ,set_isOpenEdit
  
}) {

  const {isOpen,set_isOpen} = useContext(ModalContext)

  const startPlayerTimestamp = async (timestamp) => {
       
       
    await set_player_state({});

    let player_object = {...player_state
    ,file:`${file}`
    ,start_seek: getTimestamp(timestamp) !==0 
                ? timestamp 
                : 0
    ,seek: getTimestamp(timestamp) !==0 
            ? timestamp 
            : 0
    ,seek_ratio: getTimestamp(timestamp) !==0 
                    ? (timestamp * 100) / duration 
                    : 0
    ,seek_slider: getTimestamp(timestamp) !==0 
                    ? (timestamp * 100) / duration 
                    : 0
    ,post_id: item_id
    ,content_id: content_id
    ,content_table: content_table
    ,user_id: owner_id
    ,user_name: owner_name
    ,player_type: 'file'
    ,title: item_name
    ,duration: duration
    ,stream_start_date: start_date
    ,start_date: start_date
    ,isPlaying:true
    ,isMuted:false
    ,isEnded:false
    ,avatar_url: avatar_url
    ,isHide: "0"
    }

    console.log("player_object",player_object)

    await set_player_state(player_object);

}

  const [isMounted,set_isMounted] = useState(false)

  useEffect(() => {
    set_isMounted(true)
  }, [])

  const router = useRouter()


  const ref = useRef(null);
  //const openMenu = () => {if (ref.current) ref.current.open()};
  const closeMenu = () => {if (ref.current) ref.current.close()};
  //const toggleMenu = () => {if (ref.current) ref.current.toggle()};

  const PopupTrigger = <div 
                        aria-haspopup="true"
                        className="menu-item w-5 h-5 flex-0 "
                        > 
                          <SvgJsx 
                            type='outline' 
                            icon='dots-v' 
                            className={`cursor-pointer  flex-0 mr-1 rounded-md w-5 h-5
                                          text-gray-400 hover:text-gray-200`} 
                            title='More'
                          />  
                        </div>


  return (
    <div className="menu flex items-center content-center  flex-0  ">
      {!isMounted &&
      PopupTrigger
      }
      {isMounted &&
      <Popup
        trigger={PopupTrigger}
        position="left top"
        on="click"
        closeOnDocumentClick
        closeOnEscape
        mouseLeaveDelay={300}
        mouseEnterDelay={0}
        contentStyle={{ padding: '0px', border: 'none',backgroundColor: 'transparent' }}
        arrow={false}
        nested
        ref={isMounted ? ref : null}
      >
        <div className="menu flex flex-col gap-y-1 rounded-md shadow-md p-2
                      bg-gray-800 "
        >

        
          <PopupMenuOption
            isDisplay={true}
            onClick={()=>{
              closeMenu();
              router.push(permaurl);
            }}
            icon="link-sm"
            title="Permalink"
          />
        
          <PopupMenuOption
            isDisplay={player_state?.post_id?.toString() == item_id?.toString()}
            onClick={()=>{
              closeMenu();
              router.push(`${permaurl}?t=${player_state?.seek}`);
            }}
            icon="link-sm"
            title="Current Timestamp"
          />

          <PopupMenuOption
            isDisplay={isAdmin}
            onClick={()=>{
              closeMenu();
              set_isOpenEdit(item_id);
              set_player_state(prev=>{return {...prev,isPlaying: false}})
            }}
            icon="scissors-sm"
            title="Create a Clip (WIP)"
          />

          <PopupMenuOption
            isDisplay={bol_transcribe?.toString() == "1"
                      && transcribe_status?.toString() == "1"
                    }
            onClick={()=>{
              closeMenu();
              router.push(`${permaurl}?show=transcript#tabs`);
            }}
            icon="document-text-sm"
            title={"View Transcript"}
          />

        <PopupMenuOption
            isDisplay={post_type?.toString() == "5" && stream_status?.toString()=="0" && bol_replay_host?.toString()=="1"}
            onClick={()=>{
              closeMenu();
              router.push(`${permaurl}?show=replay#tabs`);
            }}
            icon="chat-alt-sm"
            title={"Replay Chat"}
          />

        {/* <PopupMenuOption
            isDisplay={post_type?.toString() == "5" && stream_status?.toString()=="0"}
            onClick={()=>{
              closeMenu();
              set_isOpen('replay');
              startPlayerTimestamp(ifNull(player_state?.seek,0))
            }}
            icon="chat-alt-sm"
            title={"Replay Chat"}
          /> */}
          

          {/* EDIT */}
          {isAdmin &&
          <>

          <PopupMenuOption
            isDisplay={true}
            onClick={()=>{
              closeMenu();
              openModal("form",item_id,post_type);
            }}
            icon="pencil-sm"
            title="Edit"
          />
          
          <PopupMenuOption
            isDisplay={(post_type > 0)}
            onClick={()=>{
              closeMenu();
              stickyHandler(item_id);
            }}
            icon="thumbtack-sm"
            title={bol_sticky?.toString() == "0" ? "Sticky Post" : "Unsticky Post"}
          />

          <PopupMenuOption
            isDisplay={isContributor
                      && ["2","5"].indexOf(post_type?.toString()) > -1
                      && bol_transcribe?.toString() == "1"
                      && transcribe_status?.toString() == "-1"
                    }
            onClick={()=>{
              closeMenu();
              transcribeHandler(item_id);
            }}
            icon="beaker-sm"
            title={"Transcribe Audio"}
          />

          <PopupMenuOption
            isDisplay={(post_type > 0)}
            onClick={()=>{
              closeMenu();
              openModal("download",item_id,post_type);
            }}
            icon="document-download-sm"
            title="Download Files"
          />
          <PopupMenuOption
            isDisplay={isAdmin}
            onClick={()=>{
              closeMenu();
              deleteHandler(item_id);
            }}
            icon="trash-sm"
            title="Delete"
          />

          {/* <PopupMenuOption
            isDisplay={true}
            onClick={()=>{
              closeMenu();
              reportHandler(item_id);
            }}
            icon="flag-sm"
            title="Report"
          /> */}

          <div className="text-center text-xs mt-1 italic">
            {post_date}
          </div>
          
          </>
          }

        </div>
      </Popup>
      }
  </div>
  );


  
}