
import { PlayerContext } from '@/components/player/player-wrapper';
import SvgJsx from '@/components/templateux/svg/svg-jsx';
import { config, playbackRate_array } from '@/lib/config';
import { countUpBySeconds } from '@/lib/utils/countUp';
import { getAvatarUrl } from '@/lib/utils/getAvatarUrl';
import { useSWRWrapper } from '@/lib/utils/hooks/swr-hooks';
import { timerFormat } from '@/lib/utils/timerFormat';
import { useContext, useEffect, useRef, useState } from 'react';
import ReactSlider from 'react-slider'
import Image from 'next/image';
import ReactTooltip from 'react-tooltip';
import ReactPlayer from 'react-player/file'

const decimalval = 1000
const max_zoom = 200
const min_zoom_interval = 1100 // in milliseconds

const isRangeSafe = (val,min,max) => {
    if (val >= min && val <= max) return true
    return false
}

const getRatioFromSeconds = (seconds,duration,key=false) => {
    var topval = ((seconds * 100) / duration) * decimalval
    if (!key) topval = Math.floor(topval)
    let val = topval / decimalval;
    //console.log("getRatioFromSeconds",val,seconds,duration)
    return val
  }

const getSecondsFromRatio = (x,duration) => {
   let val = Math.floor(((x/100)*duration) * decimalval) / decimalval
     
    return val
}

export default function PostClipEdit({
     min=10
    ,max=90
    ,itemdata
    ,duration
    ,audio_url
    ,waveform_url=`/images/app/waveform.png`
}) {

    const scrollRef = useRef(null)
    const thumbStartRef = useRef(null)
    const thumbTrackRef = useRef(null)
    const thumbEndRef = useRef(null)
    const clipplayerRef = useRef(null)
    const waveformRef = useRef(null)
    const [clipplayer_state,set_clipplayer_state] = useState({
        isPlaying: false,
        isLoaded: false,
        isMuted: false,
        isSeeking: false,
        isLoading: false,
        isBuffering: false,
        isEnded:false,
        seek: 0,
        seek_slider: 0,
        isMediaLoaded: false,
        playbackRate: 1,
        volume: 0.8,

    })
    const {player_state,set_player_state} = useContext(PlayerContext)
    const [isMounted,set_isMounted] = useState(false)
    const [zoom,set_zoom] = useState(0)
    const [zoom_last,set_zoom_last] = useState(0)
    const [volume_slider,set_volume_slider] = useState(0)
    const [edit_mode,set_edit_mode] = useState('crop')
    const [cache,set_cache] = useState(null)
    const [timestamp_index,set_timestamp_index] = useState([])
    const [timestamp_full,set_timestamp_full] = useState([])
    const [show_advanced,set_show_advanced] = useState(false)
    const [default_cap,set_default_cap] = useState([getSecondsFromRatio(min,duration),
                                                    getSecondsFromRatio(min,duration),
                                                    getSecondsFromRatio(max,duration)])

    useEffect(()=>{
        set_isMounted(true)
    },[])


    // const doScroll = async ()=> {
    //     let leftscroll = (scrollRef?.current?.scrollWidth * (cap_slider[1]/100)) - (scrollRef?.current?.getBoundingClientRect()?.width/2) 
    //     await togglePlay(-1);
    //     await scrollRef?.current?.scrollTo({
    //         left: leftscroll,
    //         behavior: 'smooth'
    //     })
       
    // }

    // useEffect(()=>{
    //     if (clipplayer_state?.isPlaying 
    //         && thumbTrackRef?.current 
    //         && !isInViewport(thumbTrackRef?.current?.getBoundingClientRect())) {

    //         console.log("findtrack triggered in UE")

    //         doScroll();
    //     }
    // },[
    //     thumbTrackRef?.current?.getBoundingClientRect()?.right
    // ])

    useEffect(()=>{

        //generate track caps
        let default_arr = [getSecondsFromRatio(min,duration),
                        getSecondsFromRatio(min,duration),
                        getSecondsFromRatio(max,duration)]
        set_default_cap(default_arr);
        set_cap(default_arr)
        set_last_cap(default_arr)

        //generate ruler marks
        let {tindex,tfull} = getRulerMarks(duration)
        set_timestamp_index(tindex?.splice(0,tindex.length-1));
        set_timestamp_full(tfull);

    },[duration])





    useEffect(()=>{

         //postive zoom = simple math
            

        let zoom_dir = (zoom - zoom_last)

        if (zoom_dir > 0) {
            //postive zoom = simple math
            scrollRef?.current?.scrollTo({
                // left: (scrollRef?.current?.scrollWidth * (cap_slider[1]/100)) - (scrollRef?.current?.getBoundingClientRect()?.width/2),
                left: (scrollRef?.current?.scrollWidth * (cap_slider[1]/100)) - (scrollRef?.current?.getBoundingClientRect()?.width/2),
                behavior: "instant",
              })
        } else { //negative zoom = complex

            //don't change positions when moving back to zoom level 0 (100%)
            if (zoom > 0) {
                scrollRef?.current?.scrollTo({
                    // left: (scrollRef?.current?.scrollWidth * (cap_slider[1]/100)) - (scrollRef?.current?.getBoundingClientRect()?.width/2),
                    left: (scrollRef?.current?.scrollWidth * (cap_slider[1]/100)) - (scrollRef?.current?.getBoundingClientRect()?.width) - (scrollRef?.current?.getBoundingClientRect()?.width/2),
                    behavior: "instant",
                  })
                }
        }

    },[zoom])



    const [cap_slider,set_cap_slider] = useState([min,min,max])
    
    const [cap,set_cap] = useState(default_cap)
    const [last_cap,set_last_cap] = useState(default_cap)                
    const [active_index,set_active_index] = useState(1)

    const { 
        data: timestamparraydata
      , loading: timestamparraydataisLoading
      , error: timestamparraydataisError
      , boundMutate: itemsMutate
                      } = useSWRWrapper(
                        itemdata?.post_id?.toString() //id
                        ,`/api/private/timestamp/select?id=${itemdata?.post_id}&cache=${cache}` //key
                        ,true //retry?
                        ,config.max_retries //max number of retries
                        ,config.retry_interval //retry interval
                        ,0  //refreshinterval (0=none)
                        )  
                        
    useEffect(()=> {
        let temp_index = [];
        let temp_full = [];

        if (!timestamparraydataisLoading
            && !timestamparraydataisError
            && timestamparraydata) {

                timestamparraydata.map((e,index)=> {
                    let ratio = getRatioFromSeconds(e?.duration,duration,true)
                    if(isRangeSafe(ratio,0,100)) {//exclude vals outside of the timeframe
                        temp_index.push(ratio) 
                        temp_full.push({...e,key: ratio, mark_type: 'timestamp'}) 
                    }
                })

                

            }
            let {tindex,tfull} = getRulerMarks(duration)
            temp_index = temp_index.concat(tindex)
            temp_full = temp_full.concat(tfull)
            set_timestamp_index(temp_index?.splice(0,temp_index.length-1));
            set_timestamp_full(temp_full);
    },[
        timestamparraydata,
        timestamparraydataisLoading,
        timestamparraydataisError,
        zoom
    ])

    useEffect(()=>{
        if (last_cap[0] !== cap[0]) set_active_index(0)
        if (last_cap[1] !== cap[1]) set_active_index(1)
        if (last_cap[2] !== cap[2]) set_active_index(2)
    },[
        last_cap,
        cap
    ])

    const zoom_level = (level,duration) => {

        let ticks = Math.floor(scrollRef?.current?.getBoundingClientRect().width/75)
        
        let containerwidth = scrollRef?.current?.getBoundingClientRect().width;
        //let wavewidth = waveformRef?.current?.getBoundingClientRect().width;
        let wavewidth = scrollRef?.current?.scrollWidth;

        let extra_ratio = wavewidth / containerwidth;
        let total_ticks = Math.floor(ticks * extra_ratio)

        if (level==0) return {width:'100%',ticks:ticks};
        return {width:`${100+(level*100)}%`,ticks:total_ticks};
    }

    const isInViewport = (element) => {
        var outcome = false;
        if (element) {
           outcome = (
                element.left >= 0 &&
                element.right <= scrollRef.current.getBoundingClientRect().width+40 //(window.innerWidth || document.documentElement.clientWidth)
            )
        }
        return outcome;
    }

    const timerStr =(n) => {
        return timerFormat(countUpBySeconds(Math.floor(n)));
    }

    const getRulerMarks = (duration) => {

        let increment = zoom_level(zoom,duration).ticks

        return genRulerArray(duration,increment) 
    }

    const genRulerArray = (duration,increment) => {

        //add the first mark plus initiate the array
        let temp_index = [0];
        let temp_full = [{
            key:0,
            label:timerStr(0),
            mark_type:'ruler'
            }];

        let individual_step = Math.floor(duration / increment);

        //add the dynamic marks
        for (let step = individual_step; (step < duration); step+=individual_step) {

            temp_index.push(getRatioFromSeconds(step,duration,true)-0.0000001)
            temp_full.push({
                        key: (getRatioFromSeconds(step,duration,true)-0.0000001),
                        label:timerStr(step),
                        mark_type:'ruler'
                        })
          }

        return {tindex: temp_index, tfull: temp_full};
    }

    const doZoom = (val,addition) => {
        const output = val+addition;
        const tick_range = (duration/(zoom_level(output,duration)?.ticks))*1000;

        if (output >= 0 
            && output <= max_zoom 
            && ((tick_range) >= min_zoom_interval || addition == -1) //override for zoom outs
            ) 
            {
                return output;
        }
        if (output < 0) return 0
        if (output > max_zoom) return val
        return val;
    }


    const getMilliseconds = (index) => {
        var decimal = (cap[index] - Math.floor(cap[index]))
        return (decimal > 0) ? `${decimal?.toFixed(3)?.toString().substr(1,decimal.toString().length)}` : ``
    }

    const goZoom = async (dir) => {
            await set_zoom_last(zoom);
            await set_zoom(prev=>doZoom(prev,dir))
    }

    const doSeek = (seekto,x) => {
       clipplayerRef?.current?.seekTo(seekto+.0001, 'seconds');
       set_clipplayer_state(prev=>{return {...prev,seek: seekto+.0001, seek_slider:x}})
        return 
      }

    const doSeekButton = (num) => {
       let newseek = clipplayer_state?.seek+num >= cap[0]
                       ? clipplayer_state?.seek+num <= cap[2] 
                        ? clipplayer_state?.seek+num
                        : cap[0]
                       : cap[0];
        let newratio = getRatioFromSeconds(newseek,duration)
        doSeek(newseek,newratio)
    }

    const SeekButton = ({val}) => {
        
        return (<>
        <div className="cursor-pointer h-8 w-8 sm:h-12 sm:w-12 border border-gray-400 hover:border-white flex items-center content-center justify-center rounded-full"
                onClick={async ()=>{
                    doSeekButton(val);
                }}
            >
                
                <div className="text-xs sm:text-sm">
                    {val > 0 ? `+${val}` : val}
                </div>
                
            </div>
        </>)
    }

    const ClipTool = ({val}) => {
        return (<div className={`cursor-pointer text-xs border px-2 py-1 ${edit_mode==val ? 'bg-blue-500': ''}`}
                     onClick={()=>set_edit_mode(val)}
                >
                    {val}
                </div>)
    }

      const onReady=()=> {}
      
      const onProgress= async (state)=> {

        //console.log("state",state)

        //underage
        if (state.playedSeconds < cap[0]) {

          //  console.log("underage")

            clipplayerRef?.current?.seekTo(cap[0], 'seconds');

            await set_clipplayer_state(prev=>{return{...prev,
                seek: cap[0],
                seek_slider: cap_slider[0]
            }})
            await set_cap(prev=>[
                prev[0],
                cap[0],
                prev[2]
            ]);
            await set_cap_slider(prev=>[
                prev[0],
               cap_slider[0],
                prev[2]
            ]);

            return

        }

        //overage
        if (state.playedSeconds > cap[2]) {

            
          //  console.log("overage")

            clipplayerRef?.current?.seekTo(cap[0], 'seconds');

            await set_clipplayer_state(prev=>{return{...prev,
                seek: cap[0],
                seek_slider: cap_slider[0]
            }})
            await set_cap(prev=>[
                prev[0],
                cap[0],
                prev[2]
            ]);
            await set_cap_slider(prev=>[
                prev[0],
               cap_slider[0],
                prev[2]
            ]);

            return

        }

       // console.log("good to go")
       if (state.playedSeconds > cap[0] 
            && state.playedSeconds <= cap[2]) {

                // clipplayerRef?.current?.seekTo(cap[0], 'seconds');

                //good to go
                await set_clipplayer_state(prev=>{return{...prev,
                    seek: state.playedSeconds,
                    seek_slider: state.played * 100
                }})
                await set_cap(prev=>[
                    prev[0],
                    state.playedSeconds,
                    prev[2]
                ]);
                await set_cap_slider(prev=>[
                    prev[0],
                    state.played * 100,
                    prev[2]
                ]);
        }
      
      }

      const togglePlay=(num=0)=> {

        //clipplayerRef?.current?.seekTo(cap[0], 'seconds')
        doSeek(clipplayer_state?.seek,clipplayer_state?.seek_slider)
        
        set_clipplayer_state(prev=>{
            return {...prev,
                isPlaying: num==0 
                    ? !prev.isPlaying 
                    : num==1 
                        ? true 
                        : false}
        })
      }
    
      //use this to see if we have any changes in the markers so we can update the marker visuals
      const onBeforeChange=async (x_arr) => {
            set_last_cap([
                getSecondsFromRatio(x_arr[0],duration),
                getSecondsFromRatio(x_arr[1],duration),
                getSecondsFromRatio(x_arr[2],duration)
            ]);
      }

      const onChange=async (x_arr) => {
        set_cap_slider(x_arr);
        set_cap([
                    getSecondsFromRatio(x_arr[0],duration),
                    getSecondsFromRatio(x_arr[1],duration),
                    getSecondsFromRatio(x_arr[2],duration)
                ]);

        //function to update player_state for middle
        let seconds = await getSecondsFromRatio(x_arr[1],duration);
        let ratio = await getRatioFromSeconds(seconds,duration);
        
             
        if (active_index == 1) {
            await doSeek(seconds,ratio);
        }
     }

     const handleSubmit = async () => {

        console.log("submission",{start: cap[0], end: cap[2], file: audio_url})
        const res = await fetch('/api/playground/cloudinary-test', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            start: cap[0],
            end: cap[2],
            file: audio_url
          }),
        })

        const json = await res.json()
        if (json) {
          const { outcome } = json[0]
          console.log(outcome)
        }
        
    }

      
    return (<>

    <div className="w-full z-[1000]">

        <div className="hidden">
            <ReactPlayer
                ref={clipplayerRef}
                url={audio_url}
                config={{
                        forceAudio: true
                }}
                width='100%'
                height='40px'
                autoPlay={clipplayer_state?.isMediaLoaded}
                preload={"auto"}
                controls={false}
                playing={clipplayer_state.isPlaying}
                muted={clipplayer_state?.isMuted}
                volume={clipplayer_state?.volume}
                playbackRate={clipplayer_state?.playbackRate}
                onReady={onReady}
                // onReady={onReady}
                progressInterval={1}
                onProgress={onProgress}
                // onStart={onStart}
                onPlay={()=> {

                    set_clipplayer_state({...clipplayer_state
                            ,isPlaying:true
                            ,isSeeking:false
                            ,isLoading:false
                            ,isMediaLoaded:true
                        })
                }}
                // onPause={()=> {
                //     set_clipplayer_state({...clipplayer_state
                //         ,isPlaying:false
                //         ,isSeeking:false
                //     })
                // }}
                // onEnded={()=> {
                //     set_clipplayer_state({...clipplayer_state
                //         ,isPlaying:false
                //         ,isSeeking:false
                //         ,isEnded:true
                //         ,seek: duration
                //         ,seek_slider: 100
                //     })
                
                // }}
                onSeek={()=> {
                    set_clipplayer_state({...clipplayer_state,isSeeking:true})
                }}
                onError={(e)=> console.log("error",e)}
                onBuffer={()=> set_clipplayer_state({...clipplayer_state,isBuffering:true})}
                onBufferEnd={()=> set_clipplayer_state({...clipplayer_state,isBuffering:false})}
            />
        </div>

        <div className="flex  items-center content-center w-full mb-4">
            
            <div className="hidden sm:block">
                <select 
                    className='font-normal bg-gray-900 text-white border border-white rounded-md ml-1 text-xs py-0.5 w-32' 
                    defaultValue={clipplayer_state?.playbackRate}
                    onChange={async (e)=> {
                        await set_clipplayer_state(prev=>{
                            return {...prev,
                            playbackRate: parseFloat(e.target.value),
                            isPlaying: true
                    }})}}
                    >
                    {playbackRate_array.map((e,index)=> {
                    return (<option
                                key={index}
                            
                                value={e.speed}
                            >{e.text}</option>)
                    })}
                </select>
            </div>

            <div className="flex-1 flex items-center content-center sm:justify-center">
                
                <SeekButton
                    val={-15}
                />
                
                <div className=""
                    onClick={async ()=>{
                        await togglePlay(0);
                    }}
                >
                    

                    <SvgJsx 
                        type={'fill'}
                        icon={clipplayer_state?.isPlaying ? "pause-sm" : "play-sm"}
                        className={`cursor-pointer h-12 w-12 sm:h-20 sm:w-20 group-hover:opacity-80 group-hover:animate-wiggle`}
                        title={clipplayer_state?.isPlaying ? "pause" : "play"}
                    />
                </div>
                <SeekButton
                    val={30}
                />
            </div>
            <div className="flex-0 w-32">
                
                <div className="w-full flex items-center content-center  cursor-pointer">
                    <div
                         onClick={()=>{
                            set_volume_slider(0)
                            set_clipplayer_state(prev=>{
                                return {...prev,
                                        volume: 0}
                            })
                        }}
                    >
                        <SvgJsx 
                            type={'fill'}
                            icon={'volume-up-sm'}
                            className={` h-3 w-3 mr-1 ${clipplayer_state?.volume == 0 ? "opacity-20" : "hover:opacity-80"}`}
                            title={'Zoom out'}
                        />
                    </div>
                    <div className="flex-1 pb-2 w-full">
                    <ReactSlider
                                ariaLabelledby="slider-label"
                                className="horizontal-slider"
                                thumbClassName="volumethumb"
                                trackClassName="volumetrack"
                                renderTrack={(props, state) => <div {...props} className={`${props.key == `volumetrack-${0}` ? "bg-blue-400" : "bg-gray-400"} h-2 rounded-full  cursor-pointer`}></div>}
                                renderThumb={(props, state) => <div {...props} className="bg-white -top-1 w-4 h-4 rounded-full text-3xs text-black cursor-pointer"></div>}
                                onChange={async (x) => {
                                    set_volume_slider(Math.floor(x))
                                    set_clipplayer_state(prev=>{
                                        return {...prev,
                                                volume: x/100}
                                    })
                                }}
                                defaultValue={volume_slider}
                                value={volume_slider}
                                //pearling={true}
                                minDistance={0}
                                step={1}
                        />
                    </div>
                    <div className="text-gray-300 text-2xs ml-1 cursor-pointer"
                        onClick={()=>{
                            set_volume_slider(100)
                            set_clipplayer_state(prev=>{
                                return {...prev,
                                        volume: 1}
                            })
                        }}
                    >
                        {Math.floor(clipplayer_state?.volume*100)}%
                    </div>
                </div>
                <div className="block sm:hidden">
                    <select 
                        className='font-normal bg-gray-900 text-white border border-white rounded-md ml-1 text-xs py-0.5 w-32' 
                        defaultValue={clipplayer_state?.playbackRate}
                        onChange={async (e)=> {
                            await set_clipplayer_state(prev=>{
                                return {...prev,
                                playbackRate: parseFloat(e.target.value),
                                isPlaying: true
                        }})}}
                        >
                        {playbackRate_array.map((e,index)=> {
                        return (<option
                                    key={index}
                                
                                    value={e.speed}
                                >{e.text}</option>)
                        })}
                    </select>
                </div>
                
               
            </div>
        </div>

        <div className="">
                        {/* {audioObject} */}

                        <div className="flex items-center content-center  pb-1 text-xs text-gray-300">
                                    <div className="flex-1">
                                    {timerStr(cap[1])}{getMilliseconds(1)}
                                        
                                    </div>
                                    <div>
                                        {timerStr(duration)}
                                    </div>
                                </div>
                        
                            <div className="w-full mb-1">

                                    <ReactSlider
                                        ariaLabelledby="slider-label"
                                        className="horizontal-slider"
                                        thumbClassName="seekthumb"
                                        trackClassName="seektrack"
                                        renderTrack={(props, state) => <div {...props} 
                                        
                                        className={`${{
                                            "seektrack-0": "bg-gray-600",
                                            "seektrack-1": "bg-white",
                                            "seektrack-2": "bg-white",
                                        }[props.key] || "bg-gray-600"} h-2 rounded-full`}></div>}
                                        renderThumb={(props, state) => { 
                                            
                                            return (<div 
                                                        {...props} 
                                                        className={`cursor-pointer border  
                                                        
                                                                ${{
                                                                    "seekthumb-0": "bg-green-500",
                                                                    "seekthumb-1": "bg-blue-500",
                                                                    "seekthumb-2": "bg-red-500",
                                                                }[props.key] || "bg-gray-500"}

                                                                ${state.index == active_index 
                                                                    ? "border-white" 
                                                                    : "border-transparent"}
                                                                   
                                                                -top-1 w-4 h-4 rounded-full`}
                                                        onClick={()=>{
                                                            set_active_index(state.index)
                                                            let leftScroll = (scrollRef?.current?.scrollWidth * (cap_slider[state?.index]/100)) - (scrollRef?.current?.getBoundingClientRect()?.width/2)
                                                            scrollRef?.current?.scrollTo({
                                                                
                                                                // left: (scrollRef?.current?.scrollWidth * (cap_slider[1]/100)) - (scrollRef?.current?.getBoundingClientRect()?.width/2),
                                                                left: leftScroll,
                                                                behavior: "smooth",
                                                              })
                                                        }}
                                                     />
                                        )}}
                                        // onBeforeChange={async (x) => {
                                        //     set_clipplayer_state(prev=>{return {...prev,isSeeking:true}})
                                        // }}
                                        // onChange={async (x) => {
                                        //     doSeek(getSecondsFromRatio(x,duration),x)
                                        // }}
                                        // onAfterChange={async (x) => {
                                        //     set_clipplayer_state(prev=>{return {...prev,isSeeking:false}})
                                        // }}
                                        onBeforeChange={onBeforeChange}
                                        onChange={onChange}
                                        defaultValue={cap_slider}
                                        value={cap_slider}
                                        pearling={true}
                                        minDistance={0}
                                        step={0.001}
                                    />
                                </div> 

                    


                    </div>
    

                                         
    
        <div 
            ref={scrollRef}
            className="relative bg-transparent  w-[full] overflow-x-scroll overflow-y-hidden scrollbar-visible h-[240px] mb-5 mt-4 pt-[50px] mx-10"
            style={{
                background:`url("${waveform_url}"`,
                backgroundRepeat: 'no-repeat',
                backgroundAttachment:'local',
                backgroundSize: '100% 140px',
                backgroundPositionY: 'center',
                imageRendering: 'pixelated'

            }}
        >
            {/* <div className="absolute left-0 h-[80px]  flex"
                style={{width: zoom_level(zoom,duration).width}}
            >
                <img 
                    ref={waveformRef}
                    src={waveform_url}
                    width={zoom_level(zoom,duration).width}
                    height={`140`}
                    className={`transition ease-in-out rendering-pixelated h-[140px] overflow-hidden border-y-2 border-[#ffffff] bg-gray-800`}
                    style={{width: zoom_level(zoom,duration).width}}
                />
                
            </div> */}
            {/* <div className="absolute bg-white h-[1px] top-[119px]"
            style={{width: zoom_level(zoom,duration).width}}
            >

            </div> */}
            
            <div className="absolute left-0  h-[180px]"
            style={{width: zoom_level(zoom,duration).width}}
            >
                


            <ReactSlider
                className="horizontal-slider"
                thumbClassName="thumb"
                trackClassName="track"
                defaultValue={cap_slider}
                value={cap_slider}
                ariaLabel={['In', 'Playhead', 'Out']}
                pearling={true}
                minDistance={0}
                step={0.001}
                marks={ timestamp_index } //remove the last item so we don't push back the image content
                renderMark={(props) => {


                    let ts = timestamp_full.filter(f=>f.key==props?.key)[0];

                    //timestamp type
                    if (ts.mark_type == "timestamp") {

                        return (<>
                                <div {...props} 
                                        
                                            className="relative cursor-pointer"
                                >
                                    <div className="absolute w-[1px] h-[46px] border-l border-dashed border-orange-500 -top-[36px]">

                                    </div>
                                    <div 
                                        className="absolute text-3xs -top-[5px] left-[1px] px-[1px] bg-gray-800 text-gray-300"
                                    >
                                        {timerStr(ts.duration)}
                                    </div>
                                    <div
                                            data-tip
                                            data-for={`helptip-${props?.key}`}
                                            className="absolute w-5 h-5 -left-[9px] -top-[36px] border rounded-full border-orange-500">
                                                <Image
                                                    src={getAvatarUrl(
                                                        ts.avatar_url
                                                        ,ts.avatar_url_sfw
                                                        ,ts.bol_nsfw
                                                        ,ts.avatar_crdate
                                                        ,''
                                                        ,ts.user_id
                                                    )}
                                                    alt={ts.user_name}
                                                    
                                                    width={25}
                                                    height={25}
                                                    quality={50}
                                                    placeholder="empty"
                                                    loading="lazy"
                                                    layout="intrinsic"
                                                    className={` object-cover rounded-full overflow-hidden flex-none  bg-gray-200 `}
                                                />
                                               
                                            {isMounted && 
                                                <ReactTooltip
                                                    id={`helptip-${props?.key}`}
                                                    border={true}
                                                    
                                                    borderClass={`border-white`}
                                                    backgroundColor={`#0000ff`}
                                                    place={`top`} 
                                                    effect='solid' 
                                                    clickable={true}
                                                    delayHide={0}
                                                    delayUpdate={0}
                                                    className="reacttooltip rounded-3xl shadow-lg bg-red-600"
                                                    >
                                                        <div className="w-32 helptips">
                                                        {timerStr(ts.duration)} {ts.notes} (added by {ts.user_name}) {JSON.parse(ts?.tag_json)?.map((t,index)=> {return (<span key={index}>#{t?.t}</span>)})}
                                                        </div>
                                                    </ReactTooltip>
                                                }
                                    </div>
                                </div>
                            </>)
                    } else {
                    //ruler type

                        return (<><div {...props} className="relative">
                                <div className="absolute border-l top-[130px] h-[11px]">

                                </div>
                                <div 
                                    className="absolute text-3xs top-[132px] left-[1px] px-[1px] bg-gray-800 text-gray-300"
                                >
                                    {ts.label}
                                </div>
                            </div></>
                                        
                           )
                    }
                }}
                renderTrack={(props, state) => {

                    return (<div {...props} 
                        
                            className={`-top-1 bottom-0 h-[140px]
                    
                            ${{
                                "track-0": "bg-black opacity-80",
                                "track-1": "opacity-100",
                                "track-2": "opacity-100",
                            }[props.key] || "bg-black opacity-80"}

                            `}>
                               
                            </div>)
                }}

                renderThumb={(props, state) => {


                    return (<div {...props} 
                    
                    
                                onClick={()=>{
                                    set_active_index(state?.index)
                                }}
                                className="bg-black text-white font-bold relative cursor-pointer">
                                {/* {state.valueNow} */}
                                <div className={`absolute bg-gray-900 z-[100] rounded-none -top-4  text-xs 
                                        
                                            block 
                                            ${active_index == state?.index ? ' font-bold' : 'font-normal'}
                                                ${{
                                                    "thumb-0": "text-green-500",
                                                    "thumb-1": `text-blue-500 ${active_index == 1 ? '-left-[73px]' : '-left-[45px]'}`,
                                                    "thumb-2": `text-red-500 ${active_index == 2 ? '-left-[73px]' : '-left-[45px]'}`,
                                                }[props.key]}
                                `}> {{
                                    "thumb-0": `${timerStr(cap[0])}${active_index == 0 ? getMilliseconds(0) : ''}`,
                                    "thumb-1": `${timerStr(cap[1])}${active_index == 1 ? getMilliseconds(1) : ''}`,
                                    "thumb-2": `${timerStr(cap[2])}${active_index == 2 ? getMilliseconds(2) : ''}`,
                                }[props.key]}</div>
                                <div className={`absolute z-[100]
                                            ${{
                                                "thumb-0": "h-[170px]",
                                                "thumb-1": "h-[150px]",
                                                "thumb-2": "h-[170px]",
                                            }[props.key]}
                                            bg-black w-[1px] block shadow-md rounded-md text-white
                                            ${{
                                                "thumb-0": "bg-green-500 ",
                                                "thumb-1": "bg-blue-500",
                                                "thumb-2": "bg-red-500 -left-[1px]",
                                            }[props.key]}
                                `}></div>
                                {props.key == "thumb-0" &&
                                <div 
                                    ref={thumbStartRef}
                                    className={`absolute  z-[100] h-[172px]  
                                                flex flex-col items-end content-end`}>
                                    <div className="flex-1 " />
                                    
                                    <div className={`flex-0 shadow-md rounded-r-full
                                                        bg-green-500 h-8 w-8 
                                                         text-2xs flex items-center content-center justify-center`}>
                                    start
                                    </div>
                                </div>
                                }
                                {props.key == "thumb-1" &&
                                <div 
                                    ref={thumbStartRef}
                                    className={`absolute  z-[100] h-[168px]  -left-[23px]
                                                flex flex-col items-end content-end`}>
                                    <div className="flex-1 " />
                                    
                                    <div className={`flex-0 shadow-md 
                                                        bg-blue-500  h-6 w-6 rounded-sm
                                                         text-2xs flex items-center content-center justify-center`}>
                                                            <SvgJsx 
                                                                type={'fill'}
                                                                icon={'musical-note-sm'}
                                                                className={` h-4 w-4`}
                                                                title={'Collapse'}
                                                            />
                                    
                                    </div>
                                </div>
                                }
                                {/* {props.key=="thumb-1" &&
                                <div 
                                    ref={thumbTrackRef}
                                    className={`absolute  z-[100] shadow-md 
                                    border-blue-500 top-[118px]  rounded-full -left-[22px] border-[22px] h-[22px] w-[22px]
                                                       `}
                                                       style={{
                                                            
                                                                "thumb-1": {zIndex: 10000, borderTop: '22px solid transparent', borderLeft: '22px solid transparent', borderRight: '22px solid transparent'},
                                                           
                                                            }[props.key]}
                                                      
                                                       >
                                </div>
                                } */}
                                {props.key == "thumb-2" &&
                                <div 
                                    ref={thumbEndRef}
                                    className={`absolute  z-[100]  
                                    h-[172px] -left-[32px]
                                            
                                
                                                flex flex-col items-end content-end`}>
                                    <div className="flex-1 " />
                                    
                                    <div className={`flex-0 shadow-md rounded-l-full
                                    bg-red-500 h-8 w-8
                                                       
                                    text-2xs flex items-center content-center justify-center`}>
                                    end
                                    </div>
                                </div>
                                }
                            </div>)
                }}

                
                onBeforeChange={onBeforeChange}
                onChange={onChange}

            />
            </div>

            </div>


           

       
        <div 
           
            className="mb-2 rounded-md flex items-center content-center"
        
        >



            <div 
            
            className="flex-1">
                <button
                    data-tip
                    data-for={`helptip-advanced`}
                    className="underline hover:no-underlin w-48 "
                    onClick={()=>set_show_advanced(prev=>!prev)}
                >
                {!show_advanced ? "Show" : "Hide"} Advanced Controls
                </button>
            </div>
            <div className="flex items-center content-center">

                {/* <div className="mr-2 flex items-center content-center ">
                    <ClipTool
                        val={'crop'}
                    />
                    <ClipTool
                        val={'bleep'}
                    />
                </div> */}

                <div className="flex items-center content-center">
                    <div className="mr-1 text-gray-400 text-3xs cursor-pointer"
                     onClick={async ()=>{
                        await set_zoom_last(0);
                        await set_zoom(0);
                     }}
                    >
                        {(zoom*100)+100}%
                    </div>
                    
                
                    <button
                        data-tip
                        data-for={`helptip-zoom-out`}
                        onClick={()=>goZoom(-1)}
                        disabled={zoom==0}
                    >
                        <SvgJsx 
                            type={'fill'}
                            icon={'zoom-out-sm'}
                            className={` h-6 w-6 ${zoom==0 ? "opacity-20" : "hover:opacity-80"}`}
                            title={'Zoom out'}
                        />
                    </button>

                    

                    <button
                        data-tip
                        data-for={`helptip-zoom-in`}
                        onClick={()=>goZoom(1)}
                        disabled={(duration/(zoom_level(zoom+1,duration)?.ticks))*1000 < min_zoom_interval}
                    >
                        <SvgJsx 
                            type={'fill'}
                            icon={'zoom-in-sm'}
                            className={` h-6 w-6  ${(duration/(zoom_level(zoom+1,duration)?.ticks))*1000 < min_zoom_interval ? "opacity-20" : "hover:opacity-80"}`}
                            title={'Zoom in'}
                        />
                    </button>
                    <ReactTooltip
                        id={`helptip-zoom-out`}
                        border={true}
                        
                        borderClass={`border-white`}
                        backgroundColor={`#0000ff`}
                        place={`top`} 
                        effect='solid' 
                        clickable={true}
                        delayHide={0}
                        delayUpdate={0}
                        className="reacttooltip rounded-3xl shadow-lg "
                        >
                            <div className="whitespace-nowrap helptips">
                                Zoom out
                            </div>
                    </ReactTooltip>
                    <ReactTooltip
                        id={`helptip-zoom-in`}
                        border={true}
                        
                        borderClass={`border-white`}
                        backgroundColor={`#0000ff`}
                        place={`top`} 
                        effect='solid' 
                        clickable={true}
                        delayHide={0}
                        delayUpdate={0}
                        className="reacttooltip rounded-3xl shadow-lg "
                        >
                            <div className="whitespace-nowrap helptips">
                                Zoom in
                            </div>
                    </ReactTooltip>
                </div>
            </div>
        </div>
        <ReactTooltip
            id={`helptip-advanced`}
            border={true}
            
            borderClass={`border-white`}
            backgroundColor={`#0000ff`}
            place={`top`} 
            effect='solid' 
            clickable={true}
            delayHide={0}
            delayUpdate={0}
            className="reacttooltip rounded-3xl shadow-lg "
            ><div className="w-32 helptips">
            This section lets you manually fine-tune your audio selection by tenths of a second.
            </div>
                
            </ReactTooltip>
        
        {show_advanced &&
        <div className="border p-3">





            <div className="sm:flex w-full  items-center content-center justify-center  "
               
            >
                <div className="mr-2 w-full">
                    <label htmlFor="input0">
                        <div className={`flex items-center content-center text-green-500
                                    ${active_index == 0 
                                    ? 'font-bold' 
                                    : ''}`}>
                            <div className="flex-1">
                            Clip Starts
                            </div>
                            <div className=" text-sm italic">
                            {timerStr(cap[0])}{getMilliseconds(0)}
                            </div>
                        </div>
                    </label>
                
                
                    <input 
                        id={'input0'}
                        name={'input0'}
                        value={cap[0]?.toFixed(3)}
                        type="number"
                        min={0}
                        max={cap[2]} //out value is the max
                        step={0.001}
                        className={`bg-gray-700 w-full
                        ${active_index == 0 
                            ? 'border-white ' 
                            : ''}`}
                        onFocus={()=> set_active_index(0)}
                        onChange={(e)=> {
                            let seconds = parseFloat(e?.target.value)
                            let ratio = getRatioFromSeconds(e?.target.value,duration)

                                        set_cap(prev=>[
                                            seconds,
                                            prev[1] >= seconds ? prev[1] : seconds,
                                            prev[2] >= seconds ? prev[2] : seconds
                                        ]);
                                        set_cap_slider(prev=>[
                                            ratio,
                                            prev[1] >= ratio ? prev[1] : ratio,
                                            prev[2] >= ratio ? prev[2] : ratio
                                        ]);
                                    } 
                        }
                    />
            
                
                </div>
                <div className="mr-2 w-full  mt-2 sm:mt-0">
                    <label htmlFor="input1">
                        <div className={`flex items-center content-center text-blue-500
                                    ${active_index == 1 
                                    ? 'font-bold' 
                                    : ''}`}>
                            <div className=" flex-1">
                            Track
                            </div>
                            <div className=" text-sm italic">
                            {timerStr(cap[1])}{getMilliseconds(1)}
                            </div>
                        </div>
                    </label>
                    
                    <input 
                        id={'input1'}
                        name={'input1'}
                        value={cap[1]?.toFixed(3)}
                        type="number"
                        //min={cap[0]}
                        //max={cap[2]}
                        step={0.001}
                        className={`bg-gray-700 w-full  
                        ${active_index == 1 
                        ? 'border-white ' 
                        : ''}`}
                        onFocus={()=> set_active_index(1)}
                        onChange={(e)=> {
                                let seconds = parseFloat(e?.target.value);
                                let ratio = getRatioFromSeconds(e?.target.value,duration)

                                doSeek(seconds,ratio);

                                set_cap(prev=>[
                                    seconds >= prev[0] ? prev[0] : seconds,
                                    seconds,
                                    seconds <= prev[2] ? prev[2] : seconds
                                ]);
                                set_cap_slider(prev=>[
                                    ratio >= prev[0] ? prev[0] : ratio,
                                    ratio,
                                    ratio <= prev[2] ? prev[2] : ratio
                                ]);
                            } 
                        }
                    />
            
                </div>
                <div className="w-full  mt-2 sm:mt-0">
                    <label htmlFor="input2">
                        <div className={`flex items-center content-center text-red-500
                                    ${active_index == 2 
                                    ? 'font-bold' 
                                    : ''}`}>
                            <div className=" flex-1">
                            Clip Ends
                            </div>
                            <div className=" text-sm italic">
                            {timerStr(cap[2])}{getMilliseconds(2)}
                            </div>
                        </div>
                    </label>
                    <input 
                        id={'input2'}
                        name={'input2'}
                        value={cap[2]?.toFixed(3)}
                        type="number"
                        min={cap[0]} //in value is the min
                        max={duration}
                        step={0.001}
                        className={`bg-gray-700 w-full 
                        ${active_index == 2 
                            ? 'border-white ' 
                            : ''}`}
                        onFocus={()=> set_active_index(2)}
                        onChange={(e)=> {
                            
                            let seconds = parseFloat(e?.target.value)
                            let ratio = getRatioFromSeconds(e?.target.value,duration)

                                        set_cap(prev=>[
                                            prev[0] <= seconds ? prev[0] : seconds,
                                            prev[1] <= seconds ? prev[1] : seconds,
                                            seconds
                                        ]);
                                        set_cap_slider(prev=>[
                                            prev[0] <= ratio ? prev[0] : ratio,
                                            prev[1] <= ratio ? prev[1] : ratio,
                                            ratio
                                        ]);
                                    } 
                        }
                    />
                
                </div>
    
            </div>
        </div>
        }


            <div className="flex-0 sm:flex mt-5">
                
                
                <div className="flex-1">
                    <div className=" text-xs">
                        Output Length:
                    </div>
                    <div className="text-2xl font-bold">
                    {timerStr(cap[2]-cap[0])} 
                    </div>
                
                </div>

                <div className=" mt-4 sm:mt-0">
                    <button 
                        className="bg-blue-600 rounded-md text-white font-bold text-2xl px-4 py-2 whitespace-nowrap"
                        onClick={()=> handleSubmit()}
                    >
                        Save as New Clip
                    </button>
                </div>
                
            </div>
    </div>
    </>)
}

