
import ModalForm from "@/components/templateux/modal/modal-form";
import { config, lookupPost } from "@/lib/config";
import { getSlugId } from "@/lib/utils/getSlugFilter";
import { hasData } from "@/lib/utils/hasData";
import { useConfirm } from "@/lib/utils/hooks/useConfirm";
import isAdminUser from "@/lib/utils/isAdminUser";
import { slugify } from "@/lib/utils/slugify";
import { useCallback, useEffect, useState } from "react";
import toast, { toastConfig } from "react-simple-toasts";
import PostFormContainer from "./post-form-container";
import PostMeta from "./post-meta";
import PostSingle from "./post-single";
import PostSingleAudioOnly from "./post-single-audio-only";

export default function PostStateBreakdown({
        headline
       ,itemdata
       ,itemdataisLoading
       ,itemdataisError
       ,userdata
       ,ownerdata
       ,display_view
       ,cache
       ,set_cache
       ,nodata
       ,navtype
       ,helptype
       ,slug
       ,router
       ,expand=false
       ,descriptionRef
       ,index
   }) { 
    const { isConfirmed } = useConfirm();

    const [isAdmin,set_isAdmin] = useState(false)
    const [isAuthor,set_isAuthor] = useState(false)
    const [isDraft,set_isDraft] = useState(itemdata?.bol_public?.toString() == "0")
    const [liked,set_liked] = useState(false)
    const [likes,set_likes] = useState(itemdata?.likes)
    const [comments,set_comments] = useState(itemdata?.comments)
    const [opened,set_opened] = useState(false)
    const [opens,set_opens] = useState(itemdata?.opens)



    //HOOKS ABOVE SECURITY CALLS
    const [isOpen, set_isOpen] = useState(null); //modal

    //COMMENTS
    const [tabs, set_tabs] = useState((itemdata?.bol_transcribe?.toString()=="1" 
                                      && itemdata?.transcribe_status?.toString()=="1") 
                                      ? 'transcript'
                                      : 'response')

    //SUBMISSION STATES
    const [submitting,set_submitting] = useState(false);
    const [changes,set_changes] = useState(false);
    const [post_item,set_post_item] = useState()

    //SUBMISSION STATES
    const [processing,set_processing] = useState(-1);
    const [form_reset,set_form_reset] = useState(1);
    const [listen,set_listen] = useState({file:'',post_id:'',user_id:''})
    const [open,set_open] = useState(false)
    const [typevalue,set_typevalue] = useState(0)
    const [modal_type,set_modal_type] = useState("form")

    function validTab(n) {
      if (!hasData(n)) return false;
      if (["response","open","stat","transcript","replay"].indexOf(n) > -1) return true;
      return false;
    }

    useEffect(()=>{
      if (validTab(router.query?.show?.toLowerCase())) set_tabs(router.query?.show?.toLowerCase())
    },[
      router.query?.show
    ])

  

    useEffect(()=>{
      set_liked(itemdata?.liked > 0)
    },[itemdata?.liked])
    
    useEffect(()=>{
      set_opened(itemdata?.opened > 0)
    },[itemdata?.opened])
    
    //MODAL FUNCTION
    async function closeModal() {
      if (changes) {
        const confirmed = await isConfirmed('You have unsaved changes. Are you sure you want to close?');
        // const confirmed = await isConfirmed({title:"Are you sure lol?",body:'this is not reversable',confirm:'lol'});
       if (confirmed) {
          set_isOpen(null)
          set_form_reset(1)
        } 
      } else {
        set_isOpen(null)
        set_form_reset(1)
      }
    }

    function openModal(type,n,value) {
      set_modal_type(type);
      set_typevalue(value);
      set_isOpen(n);
    }
    

    useEffect(() => {

      

      if (itemdata && userdata) {

          set_isAdmin(isAdminUser(
                   itemdata?.user_id?.toString()          //user_id
                  ,itemdata?.bol_staff_help    //bol_staff_help
                  ,itemdata?.staff_json        //staff_json
                  ,itemdata?.bol_admin_help    //bol_admin_help
                  ,userdata?.id?.toString()                            //my_id
                  ,userdata?.bol_admin?.toString()              //global_admin
                  ,'content'
          ))

          set_isDraft(itemdata?.bol_public?.toString() == "0")
          set_isAuthor(itemdata?.user_id?.toString() == userdata?.id?.toString())
      }

    }, [itemdata
      , userdata
  ]);

  
  async function submitHandler(
    submitbody
   ,api_destination
   ,api_method='POST'
   ,api_headers= {
     'Content-Type': 'application/json',
   }
   ,toast_message='Your changes were saved.'
 ) {
  set_submitting(true)

   //console.log(JSON.stringify(submitbody))

  try {

    
    //INFO SUBMIT
  
      set_submitting(true)
      const res = await fetch(api_destination, {
        method: api_method,
        headers: api_headers,
        body: JSON.stringify(submitbody),
      })

      //console.log("res",res)

      set_changes(false);
      set_submitting(false)
      const json = await res.json()
      if (json) {

        
        const { 
              outcome
            , post_id
            , post_title
            , slug
            , user_name
          } = json[0][0]
        // console.log(json,submitbody);
        if (outcome?.toString()=="1") {
          toastConfig({
            time: 5000,
            className: '',
            position: 'right'
          });
          toast(toast_message, { 
            time: 3000
            , className: ''
            , clickable: true
            , clickClosable: false 
          });

         
        } else {
          console.log(outcome)
        }
        set_cache(+new Date);
        set_isOpen(null);
        if (submitbody?.post_id?.toString()=="0") router.push(`/${user_name}/posts/${slugify(post_id,slug)}`)
       
      }

      

    
  } catch (e) {
   throw Error(e.message)
  }
}

    
  const deleteHandler = async (item_id) => {

    if (!isAdmin) {
        toast(`You can only delete your own content dingbat!`, { time: 1000, className: '', clickable: true, clickClosable: false });
        return -2;
    }
  
    if (hasData(item_id)) {
      const confirmed = await isConfirmed('Are you sure you want to delete this?');
      // const confirmed = await isConfirmed({title:"Are you sure lol?",body:'this is not reversable',confirm:'lol'});
     if (confirmed) {
        const res = await fetch(`/api/private/post/delete?id=${item_id}&del=1`, { method: 'DELETE' });;
        const json = await res.json()

        if (json) {

          console.log("json",json)

          
          const { outcome } = json
          if (outcome == 1) {
            toast(`Post deleted`, { time: 1000, className: '', clickable: true, clickClosable: false });

            //new cache to trigger updated swr call
            const new_cache = new Date;
            set_cache(encodeURIComponent(new_cache.toString()));
          }
        }
      }
    }
}

  const stickyHandler = useCallback(async (item_id) => {

    if (!isAdmin) {
        toast(`You can only sticky your own content!`, { time: 1000, className: '', clickable: true, clickClosable: false });
        return -2;
    }
  
    if (hasData(item_id)) {
      const confirmed = await isConfirmed('Are you sure you want to sticky this?');
      // const confirmed = await isConfirmed({title:"Are you sure lol?",body:'this is not reversable',confirm:'lol'});
     if (confirmed) {
        const res = await fetch(`/api/private/post/update-sticky?id=${item_id}`, { method: 'POST' });;
        const json = await res.json()

        if (json) {

          console.log("json",json)

          
          const { action } = json[0][0]
          if (hasData(action)) {
            if (action == 1) {
              toast(`Post stickied!`, { time: 1000, className: '', clickable: true, clickClosable: false });
            } else if (action == 0) {
              toast(`Post unstickied!`, { time: 1000, className: '', clickable: true, clickClosable: false });
            }
            //new cache to trigger updated swr call
            const new_cache = new Date;
            set_cache(encodeURIComponent(new_cache.toString()));
          } else {
            toast(`Something went wrong. Please try again.`, { time: 1000, className: '', clickable: true, clickClosable: false });
          }

        }
      }
    }
},[
  isAdmin,
  isConfirmed,
  set_cache,
  toast
])

  const transcribeHandler = useCallback(async (item_id) => {

    if (!isAdmin) {
        toast(`You can only transcribe your own content!`, { time: 1000, className: '', clickable: true, clickClosable: false });
        return -2;
    }
  
    if (hasData(item_id)) {
      const confirmed = await isConfirmed('Are you sure you want to transcribe this file? This may take a few minutes.');
      // const confirmed = await isConfirmed({title:"Are you sure lol?",body:'this is not reversable',confirm:'lol'});
     if (confirmed) {
        const res = await fetch(`/api/private/post/update-transcribe?id=${item_id}`, { method: 'POST' });;
        const json = await res.json()

        if (json) {

          console.log("json",json)

          
          const { action } = json
          if (hasData(action)) {
            if (action == 1) {
              toast(`Transcription initiated! Check back in a few minutes`, { time: 1000, className: '', clickable: true, clickClosable: false });
            } else if (action == 0) {
              toast(`Transcription unsuccessful!`, { time: 1000, className: '', clickable: true, clickClosable: false });
            }
            //new cache to trigger updated swr call
            const new_cache = new Date;
            set_cache(encodeURIComponent(new_cache.toString()));
          } else {
            toast(`Something went wrong. Please try again.`, { time: 1000, className: '', clickable: true, clickClosable: false });
          }

        }
      }
    }
},[
  isAdmin,
  isConfirmed,
  set_cache,
  toast
])


    //post view (default)
    return (<>
   

      <div className=" justify-center mx-auto w-full max-w-5xl text-center">

        {itemdata?.length==0 &&
          <div className="w-full px-4 py-5 mt-3 text-center text-md  text-white " >
            {nodata}
          </div>
        }
        
        
        <div 
          className=" justify-center flex flex-col"
        >
          {display_view == "posts" && 
          <>
           
            <PostSingle
              router={router}
              itemdata={itemdata}
              userdata={userdata}
              ownerdata={ownerdata}
              display_view={display_view}
              openModal={openModal}
              closeModal={closeModal}
              cache={cache}
              set_cache={set_cache}
              navtype={navtype}
              helptype={helptype}
              tabs={tabs}
              set_tabs={set_tabs}
              slug={slug}
              deleteHandler={deleteHandler}
              stickyHandler={stickyHandler}
              transcribeHandler={transcribeHandler}
              isDraft={isDraft}
              set_isDraft={set_isDraft}
              isAdmin={isAdmin}
              isAuthor={isAuthor}
              likes={likes}
              set_likes={set_likes}
              comments={comments}
              set_comments={set_comments}
              liked={liked}
              set_liked={set_liked}
              opens={opens}
              set_opens={set_opens}
              opened={opened}
              set_opened={set_opened}
              expand={expand}
              descriptionRef={descriptionRef}
              index={index}
            />
          </>
          }

          {display_view == "audio" && 
          <>
            <PostSingleAudioOnly
              router={router}
              itemdata={itemdata}
              userdata={userdata}
              ownerdata={ownerdata}
              display_view={display_view}
              openModal={openModal}
              closeModal={closeModal}
              cache={cache}
              set_cache={set_cache}
              navtype={navtype}
              helptype={helptype}
              tabs={tabs}
              set_tabs={set_tabs}
              slug={slug}
              deleteHandler={deleteHandler}
              stickyHandler={stickyHandler}
              transcribeHandler={transcribeHandler}
              isDraft={isDraft}
              set_isDraft={set_isDraft}
              isAdmin={isAdmin}
              isAuthor={isAuthor}
            />
          </>
          }

          {hasData(slug) &&
            <PostMeta
              profiledata={ownerdata}
              itemdata={itemdata}
              tabs={tabs}
              set_tabs={set_tabs}
              post_id={getSlugId(slug)}
              slug={slug}
              router={router}
              cache={cache}
              set_cache={set_cache}
              userdata={userdata}
              nodata={nodata}
              navtype={navtype}
              helptype={helptype}
              comments={comments}
              set_comments={set_comments}
              likes={likes}
              opens={opens}
            />
          }

        </div>
    
        
      </div>

      <ModalForm
         title={modal_type == "download"
                ? `Download Files`
                : isOpen == 0 
                  ? `New ${lookupPost("",typevalue)?.text}` 
                  : `Edit ${lookupPost("",typevalue)?.text}`}
         modal_content={(<>
                    
                     <PostFormContainer
                       modal_type={modal_type}
                       submitHandler={submitHandler}
                       submitting={submitting}
                       set_submitting={set_submitting}
                       processing={processing}
                       set_processing={set_processing}
                       set_changes={set_changes}
                       changes={changes}
                       max_files={config.post.max_files}

                       //post info
                       post_id={isOpen}
                       itemdata={itemdata}
                       itemdataisLoading={itemdataisLoading}
                       itemdataisError={itemdataisError}

                       ownerdata={ownerdata}
                       userdata={userdata}

                       show_graphic={false}
                       form_reset={form_reset}
                       set_form_reset={set_form_reset}
                       actionText={'Save changes'}
                       postcache={cache}
                       set_postcache={set_cache}
                       navtype={navtype}
                       helptype={helptype}

                       typevalue={typevalue}
                       set_typevalue={set_typevalue}
                     />
                     
                   </>)}
         isOpen={isOpen}
         closeModal={closeModal}
       />
      
    </>)

}